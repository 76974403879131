<template>
    <div class="content_overflow m-auto">
        <div class="box box_registration">
            <!--<span class="h1">Votre immatriculation</span>-->
            <p class="text">
                Tapez votre numéro d’immatriculation ou votre texte
            </p>
            <PlateNumberInput />
        </div>

        <div
            v-if="!PlaqueModule.onlyApproval"
            class="box box_checkbox box_small pt0"
        >
            <div class="row">
                <span class="h1">Texte en mode libre</span>
                <btn-switch store="customText" />
            </div>
        </div>

        <div
            class="box box_checkbox box_small pt0"
            :class="{
                hidden: !PlaqueModule.adminMode,
            }"
        >
            <div class="row">
                <span class="h1">
                    Activer la gestion de la date de validité
                </span>
                <btn-switch store="showExpiryDate" />
            </div>
        </div>

        <div
            v-if="PlaqueImmat.showExpiryDate"
            class="box box_registration"
        >
            <span class="h1">Date de validité</span>
            <p class="text">Tapez le mois et l'année (2 derniers chiffres)</p>
            <div class="number_box">
                <input
                    v-model="PlaqueImmat.expiryDate"
                    v-mask="'##/##'"
                    :masked="true"
                    type="text"
                    class="number_text"
                    placeholder="12/25"
                />
            </div>
        </div>

        <div
            v-if="!PlaqueModule.onlyApproval"
            class="box box_checkbox box_small pt0"
        >
            <div class="row">
                <span class="h1">Mode Carbone</span>
                <btn-switch store="showOverlayCarbon" />
            </div>
        </div>

        <div
            class="box box_checkbox box_small pt0"
            :class="{
                hidden: !PlaqueImmat.customText,
            }"
        >
            <div class="row">
                <span class="h1">Largeur automatique du texte</span>
                <btn-switch store="forceCustomTextScaleWidth" />
            </div>
        </div>

        <div
            v-if="!PlaqueModule.onlyApproval"
            class="box box_checkbox box_small pt0"
            :class="{
                hidden: !PlaqueImmat.customText,
            }"
        >
            <div class="row">
                <span class="h1">Activer le mode avancé</span>
                <btn-switch
                    store="isAdvancedModeEnabled"
                    namespace="PlaqueModule"
                />
            </div>
        </div>

        <hr
            class="desctop_hr"
            :class="{
                hidden: !PlaqueModule.isAdvancedModeEnabled,
            }"
        />

        <div
            class="box box_registration"
            :class="{
                hidden: !PlaqueModule.isAdvancedModeEnabled,
            }"
        >
            <p class="text">Décalage haut</p>
            <number-input
                v-model="PlaqueImmat.offsetLineTop"
                :min="-1000"
                :max="1000"
                :step="1"
            />
        </div>

        <div
            class="box box_registration"
            :class="{
                hidden: !PlaqueModule.isAdvancedModeEnabled,
            }"
        >
            <p class="text">Marge intérieure Gauche</p>
            <number-input
                v-model="PlaqueImmat.paddingLeft"
                :min="-1000"
                :max="1000"
                :step="1"
            />
        </div>

        <div
            class="box box_registration"
            :class="{
                hidden: !PlaqueModule.isAdvancedModeEnabled,
            }"
        >
            <p class="text">Marge intérieure droite</p>
            <number-input
                v-model="PlaqueImmat.paddingRight"
                :min="-1000"
                :max="1000"
                :step="1"
            />
        </div>

        <div
            class="box box_checkbox box_small pt0"
            :class="{
                hidden: !PlaqueModule.adminMode,
            }"
        >
            <div class="row">
                <span class="h1">Désactiver l'effet brillant</span>
                <btn-switch
                    store="noGloss"
                    namespace="PlaqueModule"
                />
            </div>
        </div>

        <div
            class="box box_checkbox box_small pt0"
            :class="{
                hidden: !PlaqueModule.adminMode,
            }"
        >
            <div class="row">
                <span class="h1">Forcer les tirets</span>
                <btn-switch
                    store="forceShowDashes"
                    namespace="PlaqueImmat"
                />
            </div>
        </div>

        <hr
            v-if="PlaqueImmat.showRightBand && !PlaqueImmat.showExpiryDate"
            class="desctop_hr"
        />

        <div
            v-if="PlaqueImmat.showRightBand && !PlaqueImmat.showExpiryDate"
            class="box box_small"
        >
            <span class="h1">Numéro de département</span>
            <DepartmentSelect />
        </div>

        <div
            class="box format_plaque"
            :class="{
                hidden: !PlaqueModule.adminMode,
            }"
        >
            <span class="h1">Format de plaque</span>
            <p class="text hidden">
                Choisissez l’un des deux formats standards
            </p>
            <div
                class="format_box"
                :class="{
                    hidden: !PlaqueModule.adminMode,
                }"
            >
                <div
                    v-for="(item, index) in PlaqueModule.plateFormats"
                    :key="index"
                    class="format"
                    :class="{
                        active: PlaqueImmat.format === item.name,
                    }"
                    @click="PlaqueImmat.format = item.name"
                >
                    <div class="img_box">
                        <!--<img alt="" :src="item.image">-->
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>

        <btnPrevNextSlide />

        <!--
        <div class="box format_plaque" >
            <div v-if="PlaqueModule.currentPlateFormat" class="format_info_box">
                    <span class="h3">Infos </span>

                    <span class="h3">{{
                            PlaqueModule.currentPlateFormat.title
                        }}</span>
                <p class="info" >{{
                        PlaqueModule.currentPlateFormat.subtitle
                    }}</p>
                <div class="open_select_list select_open"
                     @click="customListIsVisible = !customListIsVisible">
                    <p>{{ PlaqueModule.currentPlateFormat.dimension }}</p>
                </div>
            </div>
        </div>
        -->
    </div>
</template>

<script>
    import { mapState } from 'pinia'
    import { useStore } from '@/store'
    import { mask } from 'vue-the-mask'
    import { defineAsyncComponent } from 'vue'

    const btnSwitch = defineAsyncComponent(() => import('./Buttons/Switch.vue'))
    const btnPrevNextSlide = defineAsyncComponent(
        () => import('./Buttons/PrevNextSlide.vue')
    )

    const DepartmentSelect = defineAsyncComponent(
        () => import('./DepartmentSelect.vue')
    )

    const PlateNumberInput = defineAsyncComponent(
        () => import('./PlateNumberInput.vue')
    )

    const NumberInput = defineAsyncComponent(() => import('./NumberInput.vue'))

    export default {
        components: {
            NumberInput,
            btnSwitch,
            btnPrevNextSlide,
            DepartmentSelect,
            PlateNumberInput,
        },
        directives: {
            mask,
        },
        data() {
            return {
                plateNumber: '',
                plateNumberFree: '',
                plateNumberFreeSecondLine: '',
                customListIsVisible: false,
            }
        },
        computed: {
            ...mapState(useStore, ['Config', 'PlaqueImmat', 'PlaqueModule']),
        },
    }
</script>
