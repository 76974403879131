import { useStore } from './store'
import Compressor from 'compressorjs'

export function compressImage(file, options) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            ...options,
            success: (result) => {
                resolve(result)
            },
            error: (err) => {
                reject(err)
            },
        })
    })
}

export function convertToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    })
}

export async function convertWebPtoPNG(blob) {
    const url = URL.createObjectURL(blob)
    const img = new Image()
    await new Promise((resolve) => {
        img.onload = resolve
        img.src = url
    })
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)
    return canvas.toDataURL('image/png')
}

export function consoleLog(msg, color, force) {
    const store = useStore()
    const adminMode = store.PlaqueModule.adminMode
    switch (color) {
        case 'success':
            color = 'Green'
            break
        case 'info':
            color = 'DodgerBlue'
            break
        case 'error':
            color = 'Red'
            break
        case 'warning':
            color = 'Orange'
            break
        case 'start':
            color = 'OliveDrab'
            break
        case 'end':
            color = 'Orchid'
            break
    }

    if (adminMode || force) {
        if (typeof msg == 'object') {
            console.log(msg)
        } else {
            if (color) {
                console.log(
                    '%c' + msg,
                    'font-family:monospace; font-size: 14px;color:' + color
                )
            } else {
                console.log(
                    '%c' + msg,
                    'font-family:monospace; font-size: 14px'
                )
            }
        }
    }
}

export function removeEmoji(input) {
    if (input !== null && input !== undefined) {
        let output = Array.from(input)
            .filter((c) => c.charCodeAt(0) > 31 && c.charCodeAt(0) !== 127)
            .join('')

        output = output.replace(
            /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}]/gu,
            ''
        )

        return output
    }
    return ''
}

export const checkIfDefaultRegionLogo = (regionLogo, departementsLogos) => {
    if (departementsLogos) {
        return departementsLogos.some((value) => value.img === regionLogo)
    }
    return false
}

export function normalizeColor(color) {
    if (!color) {
        return color
    }

    let normalizedColor = color
    if (color.length === 4) {
        // Format #fff
        normalizedColor =
            '#' +
            color[1] +
            color[1] +
            color[2] +
            color[2] +
            color[3] +
            color[3]
    }

    return normalizedColor.toLowerCase()
}

export function areColorsEqual(color1, color2) {
    const normalizedColor1 = normalizeColor(color1)
    const normalizedColor2 = normalizeColor(color2)

    if (!normalizedColor1 || !normalizedColor2) {
        return false
    }

    return normalizedColor1 === normalizedColor2
}

export function isCharacterSupported(font, character) {
    if (character === '\n') return true
    return !!font.charToGlyph(character).unicode
}

export function checkCharacters(input, font) {
    if (!font) {
        return false
    }
    for (let char of input) {
        if (!isCharacterSupported(font, char)) {
            return false
        }
    }
    return true
}

export function filterValidCharacters(input, font) {
    if (!font) {
        return ''
    }

    let validCharacters = ''
    for (let char of input) {
        if (isCharacterSupported(font, char)) {
            validCharacters += char
        }
    }
    return validCharacters
}

export async function compressData(data, mimeType) {
    if ('CompressionStream' in window) {
        const utf8Encoder = new TextEncoder()
        const compressedStream = new CompressionStream('gzip')
        const stream = new ReadableStream({
            start(controller) {
                controller.enqueue(utf8Encoder.encode(data))
                controller.close()
            },
        })

        const readableStreamCompressed = stream.pipeThrough(compressedStream)
        const reader = readableStreamCompressed.getReader()

        let chunks = []
        let result
        while (!(result = await reader.read()).done) {
            chunks.push(result.value)
        }

        return new Blob(chunks, {
            type: mimeType,
        })
    } else {
        console.warn(
            "CompressionStream n'est pas supporté dans ce navigateur. Les données ne seront pas compressées."
        )
        return new Blob([data], {
            type: mimeType,
        })
    }
}

/* global dataLayer */
export const sendToGtm = (eventName, eventData = {}) => {
    consoleLog({
        event: eventName,
        ...eventData,
    })
    window.dataLayer = window.dataLayer || []
    dataLayer.push({
        event: eventName,
        ...eventData,
    })
}

export const isMobile = () => {
    return window.innerWidth < 1024
}

export function isValidHexColor(color) {
    const hexColorRegex = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i
    return hexColorRegex.test(color)
}

export function getParamsNormalProductUrl(id_product, quantity) {
    let bodyParams = new FormData()
    bodyParams.append('form[name]', 'cart')
    bodyParams.append('form[action]', 'insert')
    bodyParams.append('id_product', id_product)

    bodyParams.append('items[' + id_product + '][quantity]', quantity)

    return bodyParams
}
export async function postRequest(url, params) {
    if (window.self !== window.top) {
        // Si on est dans une iframe, on appelle saveCartData
        saveCartData(params)
        consoleLog(
            'Les données ont été envoyées au parent via saveCartData.',
            'info'
        )
    } else {
        // Sinon, on fait directement le fetch
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: params,
                credentials: 'include',
            })

            // Optionnel : Gestion de la réponse si nécessaire
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const data = await response.json()
            consoleLog(
                'Réponse du serveur reçue avec succès : ' +
                    JSON.stringify(data),
                'success'
            )
        } catch (error) {
            consoleLog(
                'Erreur lors de la requête fetch : ' + error.message,
                'error'
            )
        }
    }
}

export function saveCartData(cartData) {
    // Sérialiser les données du formulaire, y compris les blobs
    serializeFormData(cartData).then((serializedData) => {
        sendToParent(serializedData)
    })
}

function serializeFormData(formData) {
    const serializedData = []
    const promises = []

    formData.forEach((value, key) => {
        if (value instanceof Blob) {
            promises.push(
                new Promise((resolve) => {
                    const reader = new FileReader()
                    reader.onload = function (event) {
                        serializedData.push({
                            key,
                            value: event.target.result,
                            isBlob: true,
                            type: value.type,
                        })
                        resolve()
                    }
                    reader.readAsDataURL(value)
                })
            )
        } else {
            serializedData.push({ key, value, isBlob: false })
        }
    })

    return Promise.all(promises).then(() => serializedData)
}

function sendToParent(serializedData) {
    window.parent.postMessage(
        {
            action: 'cartData',
            cartData: serializedData,
        },
        '*'
    ) // Remplacez '*' par l'origine exacte si nécessaire

    consoleLog(
        'Les données du panier ont été envoyées au parent:',
        serializedData
    )
}
