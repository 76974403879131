<script setup>
    import {
        ref,
        onMounted,
        onBeforeUnmount,
        watch,
        nextTick,
        computed,
    } from 'vue'
    import ImmatSvg from '@/assets/img/slide1.svg'
    import PoliceSvg from '@/assets/img/slide2.svg'
    import PictosGaucheSvg from '@/assets/img/slide3_1.svg'
    import PictosDroiteSvg from '@/assets/img/slide3.svg'
    import FondGaucheSvg from '@/assets/img/slide4.svg'
    import FondDroiteSvg from '@/assets/img/slide5.svg'
    import MessageSvg from '@/assets/img/slide6.svg'
    import LiseresSvg from '@/assets/img/slide7.svg'
    import FondSvg from '@/assets/img/slide8.svg'
    import FinitionsSvg from '@/assets/img/slide9.svg'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Mousewheel, Pagination, Navigation } from 'swiper/modules'
    import { useStore } from '@/store/index.js'

    // Import Swiper styles
    import 'swiper/css'
    import 'swiper/css/navigation'
    import 'swiper/css/pagination'

    const navSwiper = ref(null)
    const swiperKey = ref(0) // Add a reactive key
    const setSwiper = (swiper) => {
        navSwiper.value = swiper
    }

    const updateSwiperKey = () => {
        swiperKey.value += 1 // Incrémentez la clé pour forcer le re-rendu
    }

    const store = useStore()

    const activeIndexChange = (swiper) => {
        store.setCurrentSlide(swiper.activeIndex)
    }

    const windowWidth = ref(window.innerWidth)

    const updateWindowWidth = () => {
        windowWidth.value = window.innerWidth
    }

    const isMobileComputed = computed(() => windowWidth.value < 1024)
    const swiperDirection = computed(() =>
        isMobileComputed.value ? 'horizontal' : 'vertical'
    )
    const spaceBetween = computed(() => (isMobileComputed.value ? 30 : 0))

    const handleResize = () => {
        updateWindowWidth()
        if (navSwiper.value) {
            navSwiper.value.changeDirection(swiperDirection.value) // Change direction dynamically
            navSwiper.value.update()

            // Revenir à l'index actif sauvegardé après le redimensionnement
            nextTick(() => {
                navSwiper.value.slideTo(store.PlaqueModule.currentSlide, 0) // Revenir à la slide sauvegardée sans animation
            })
        }
        updateSwiperKey()
    }

    const modules = [Mousewheel, Pagination, Navigation]
    const slideItems = [
        { imgUrl: ImmatSvg, text: 'IMMAT & FORMAT' },
        { imgUrl: PoliceSvg, text: 'POLICE' },
        { imgUrl: PictosGaucheSvg, text: 'PICTOS GAUCHE' },
        { imgUrl: PictosDroiteSvg, text: 'PICTOS DROITE' },
        { imgUrl: FondGaucheSvg, text: 'FOND BANDE GAUCHE' },
        { imgUrl: FondDroiteSvg, text: 'FOND BANDE DROITE' },
        { imgUrl: MessageSvg, text: 'MESSAGE' },
        { imgUrl: LiseresSvg, text: 'LISERÉS' },
        { imgUrl: FondSvg, text: 'FOND' },
        { imgUrl: FinitionsSvg, text: 'FINITIONS' },
    ]

    watch(windowWidth, (newWidth, oldWidth) => {
        if (newWidth !== oldWidth) {
            handleResize()
        }
    })

    watch(swiperDirection, (newDirection, oldDirection) => {
        if (newDirection !== oldDirection) {
            updateSwiperKey() // Mise à jour seulement si la direction change réellement
        }
    })

    watch(
        () => store.PlaqueModule.currentSlide,
        async (value) => {
            await nextTick()
            if (navSwiper.value && navSwiper.value.activeIndex !== value) {
                navSwiper.value.slideTo(value, 250)
                navSwiper.value.update() // Mise à jour uniquement si nécessaire
            }
        },
        { immediate: true }
    )

    onMounted(() => {
        window.addEventListener('resize', handleResize)
        nextTick(() => {
            handleResize()
        })
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize)
    })
</script>

<template>
    <div class="secondaty_slider_box">
        <swiper
            :key="swiperKey"
            :slides-per-view="'auto'"
            :centered-slides="true"
            :allow-touch-move="true"
            :space-between="spaceBetween"
            :mousewheel="{ forceToAxis: true }"
            :slide-to-clicked-slide="true"
            :direction="swiperDirection"
            class="draggable"
            breakpoints-base="container"
            :scrollbar="{ draggable: true }"
            :modules="modules"
            :pagination="{ type: 'bullets' }"
            :navigation="true"
            :center-insufficient-slides="true"
            :watch-overflow="true"
            :update-on-window-resize="true"
            :resize-observer="true"
            :breakpoints="{
                '300': {
                    centeredSlides: true,
                    slidesPerView: 'auto',
                },
                '1025': {
                    centeredSlides: true,
                    slidesPerView: 'auto',
                },
            }"
            @active-index-change="activeIndexChange"
            @swiper="setSwiper"
        >
            <swiper-slide
                v-for="slide in slideItems"
                v-slot="{ isActive }"
                :key="slide.text"
                class="slide-btn"
            >
                <div
                    :class="
                        isActive ? 'border_desctop_white' : 'slider_inactive'
                    "
                >
                    <div :class="isActive && 'border_desctop'">
                        <img
                            alt=""
                            :src="slide.imgUrl"
                            width="115"
                            height="36"
                        />
                        <p class="text">
                            {{ slide.text }}
                        </p>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<style lang="scss">
    .secondaty_slider_box {
        --swiper-navigation-top-offset: 25px;
        --swiper-navigation-sides-offset: 10px;

        user-select: none;
        padding: 5px 0 2px;

        .slider_inactive {
            opacity: 0.2;
            filter: grayscale(1);
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .swiper-pagination {
            width: 100%;
            height: 4px;
            flex-wrap: nowrap;
            bottom: 2px;
            left: 0;
            display: flex;
        }

        .swiper-pagination-bullet {
            width: 10%;
            height: 4px;
            margin: 0;
            border-radius: 0;
            background: none;
        }

        /*
    .swiper-pagination-bullet {
        width: 100%;
        height: 4px;
        transition: transform .2s linear;
        background-color: transparent;
        padding: 0
    }
*/

        .swiper-horizontal {
            .swiper-slide-active {
                background: none;
            }
        }

        .swiper-pagination-bullet-active {
            background: linear-gradient(90deg, #5b40d7 -4.17%, #e85f62 104.49%);
            transform: skewX(-30deg);
        }

        .draggable {
            height: 67px;
            width: 100%;
            position: relative;
        }
    }

    * {
        --swiper-navigation-size: 20px;
        --swiper-navigation-color: #000;
        --swiper-pagination-color: #5b40d7;
        --swiper-pagination-bottom: -2px;
        /*--swiper-pagination-bullet-horizontal-gap: 0;*/
    }

    @media all and (min-width: 1024px) {
        .secondaty_slider_box {
            padding: 0;

            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }

            .swiper-slide-active {
                background: linear-gradient(
                    180deg,
                    #5b40d7 -4.17%,
                    #e85f62 104.49%
                );
            }

            .slider_inactive {
                opacity: 0.2;
                filter: grayscale(1);
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }

            .swiper-pagination {
                display: none;
            }

            .draggable {
                position: relative;
                height: calc(100vh - 80px);
                width: 100%;
                max-height: 522px;
            }
        }
    }
</style>
