<script setup>
    import { ref, defineAsyncComponent, nextTick, watch } from 'vue'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { EffectFade } from 'swiper/modules'
    import 'swiper/css'
    import 'swiper/css/effect-fade'
    import { useStore } from '@/store/index.js'
    import { consoleLog } from '@/helpers.js'
    import PlateFormat from '@/components/PlateFormat.vue'

    // Définition des composants asynchrones
    const componentMap = {
        0: PlateFormat,
        1: defineAsyncComponent(() => import('@/components/PlateNumero.vue')),
        2: defineAsyncComponent(() => import('@/components/PictosGauche.vue')),
        3: defineAsyncComponent(() => import('@/components/PictosDroite.vue')),
        4: defineAsyncComponent(
            () => import('@/components/FondBandeGauche.vue')
        ),
        5: defineAsyncComponent(
            () => import('@/components/FondBandeDroite.vue')
        ),
        6: defineAsyncComponent(() => import('@/components/PlateMessage.vue')),
        7: defineAsyncComponent(() => import('@/components/PlateLiseres.vue')),
        8: defineAsyncComponent(() => import('@/components/PlateFond.vue')),
        9: defineAsyncComponent(
            () => import('@/components/PlateFinitions.vue')
        ),
    }

    const store = useStore()
    const mainSwiper = ref(null)
    const slides = ref([...Array(10).keys()])

    const setSwiper = (swiper) => {
        mainSwiper.value = swiper
        store.setCurrentSlide(0)
        consoleLog('Swiper initialized:', swiper)
    }

    const activeIndexChange = async (swiper) => {
        await nextTick()
        store.setCurrentSlide(swiper.activeIndex)
        consoleLog('Active index changed:', swiper.activeIndex)
    }

    // Observer les changements de slide depuis le store
    watch(
        () => store.PlaqueModule.currentSlide,
        async (value) => {
            consoleLog('Current slide changed:', value)
            if (mainSwiper.value) {
                await nextTick()
                mainSwiper.value.slideTo(value, 250)
                mainSwiper.value.update() // Forcer la mise à jour du slider
            }
        }
    )

    const getComponentForSlide = (index) => {
        return componentMap[index] || null
    }

    // Définir quels composants sont visibles en fonction du slide actif
    const isComponentVisible = (index) => {
        const currentSlide = store.PlaqueModule.currentSlide
        // Rendre le composant visible pour le slide actif et les deux adjacents
        return (
            index === currentSlide ||
            index === currentSlide - 1 ||
            index === currentSlide + 1
        )
    }

    const getSlideClasses = (index) => {
        const isApprovalSlide = [8, 9].includes(index)
        return {
            'slide-container': true,
            'slide-container_over': !isApprovalSlide,
            onlyApproval: isApprovalSlide,
        }
    }
</script>

<template>
    <swiper
        ref="sliderRef"
        class="slider-for"
        :class="'main_slider'"
        :effect="'fade'"
        :allow-touch-move="false"
        :modules="[EffectFade]"
        :watch-overflow="true"
        :update-on-window-resize="true"
        :resize-observer="true"
        @swiper="setSwiper"
        @slide-change="activeIndexChange"
    >
        <swiper-slide
            v-for="index in slides"
            :key="index"
            :class="getSlideClasses(index)"
        >
            <div
                v-if="isComponentVisible(index)"
                :class="`slide slide${index}`"
            >
                <component :is="getComponentForSlide(index)" />
            </div>
        </swiper-slide>
    </swiper>
</template>

<style scoped lang="scss">
    .swiper {
        z-index: initial;
    }
</style>
