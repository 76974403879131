import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useDialogStore = defineStore('dialog', () => {
    const dialogOptions = ref(null)
    let resolvePromise, rejectPromise

    const confirm = (options) => {
        dialogOptions.value = options
        return new Promise((resolve, reject) => {
            resolvePromise = resolve
            rejectPromise = reject
        })
    }

    const resolve = () => {
        if (resolvePromise) {
            resolvePromise()
        }
        dialogOptions.value = null
    }

    const reject = () => {
        if (rejectPromise) {
            rejectPromise()
        }
        dialogOptions.value = null
    }

    return { dialogOptions, confirm, resolve, reject }
})
