<template>
    <component :is="componentToRender" />
</template>

<script>
    import App from './App.vue'
    import Iframe from './Iframe.vue'

    export default {
        computed: {
            componentToRender() {
                // Vérifie si le paramètre iframe est dans l'URL
                return this.$route.query.iframe ? Iframe : App
            },
        },
    }
</script>
