<template>
    <div
        :class="{
            clm_loader: PlaqueModule.loading,
        }"
    >
        <transition-group
            v-if="PlaqueModule.loading"
            name="fade"
            mode="out-in"
            class="transition_text"
            tag="div"
        >
            <div
                v-if="showText"
                :key="text"
                class="text"
            >
                {{ text }}
            </div>
        </transition-group>

        <AppHeader />
        <SlideReassurance />

        <div class="main_content">
            <div class="slider_section active">
                <div class="number_img_box">
                    <PlaqueImmat />
                </div>

                <MainSlider />

                <NavSlider />

                <div class="btn_box btn_box_main">
                    <button
                        class="cancel grey_btn btn"
                        @click="goBack"
                    >
                        <img
                            alt=""
                            loading="lazy"
                            src="@/assets/img/arrow_anul.svg"
                            width="15"
                            height="16"
                        />
                        RETOUR AU SITE
                    </button>
                    <button
                        class="pay green_btn btn valider"
                        @click="addToCart"
                    >
                        <img
                            alt=""
                            loading="lazy"
                            src="@/assets/img/check.svg"
                            width="20"
                            height="18"
                        />
                        VALIDER
                    </button>
                </div>
            </div>

            <Payer />
        </div>
        <ModalSearch
            v-if="PlaqueModule.showModalSearch"
            :class="{
                show: PlaqueModule.showModalSearch,
            }"
            :action="PlaqueModule.modalSearchAction"
            :background-color="PlaqueModule.modalSearchBackgroundColor"
        />

        <ModalImport
            v-if="PlaqueModule.showModalImport"
            :class="{
                show: PlaqueModule.showModalImport,
            }"
            :image="PlaqueModule.modalImportImage"
            :action="PlaqueModule.modalImportAction"
        />

        <ModalCart
            v-if="PlaqueModule.showModalCart"
            :class="{
                show: PlaqueModule.showModalCart,
            }"
        />

        <CustomDialog
            :header-text="PlaqueModule.dialogHeaderText"
            :body-text="PlaqueModule.dialogBodyText"
            :dialog-class="PlaqueModule.dialogClass"
            ok-text="Fermer"
            :is-visible="PlaqueModule.isDialogVisible"
            @update:is-visible="PlaqueModule.isDialogVisible = $event"
        />

        <DialogComponent
            v-if="dialogOptions"
            :title="dialogOptions.title"
            :message="dialogOptions.message"
            :ok-text="dialogOptions.okText"
            :cancel-text="dialogOptions.cancelText"
            :animation="dialogOptions.animation"
            :backdrop-close="dialogOptions.backdropClose"
        />
    </div>
</template>

<script>
    import { useDialogStore } from '@/store/dialogStore'
    import NavSlider from '@/components/NavSlider.vue'
    import MainSlider from '@/components/MainSlider.vue'
    import { defineAsyncComponent } from 'vue'

    import { mapState } from 'pinia'
    import { useStore } from '@/store'
    import { consoleLog } from '@/helpers'

    const ModalCart = defineAsyncComponent(
        () => import('./components/ModalCart.vue')
    )

    const DialogComponent = defineAsyncComponent(
        () => import('./components/DialogComponent.vue')
    )

    const AppHeader = defineAsyncComponent(
        () => import('./components/AppHeader.vue')
    )
    const PlaqueImmat = defineAsyncComponent(
        () => import('./components/PlaqueImmat.vue')
    )

    const Payer = defineAsyncComponent(
        () => import('./components/PaymentPayer.vue')
    )

    const ModalSearch = defineAsyncComponent(
        () => import('./components/ModalSearch.vue')
    )
    const ModalImport = defineAsyncComponent(
        () => import('./components/ModalImport.vue')
    )
    const SlideReassurance = defineAsyncComponent(
        () => import('./components/SlideReassurance.vue')
    )

    const CustomDialog = defineAsyncComponent(
        () => import('./components/CustomDialog.vue')
    )
    export default {
        name: 'App',
        components: {
            DialogComponent,
            MainSlider,
            NavSlider,
            ModalCart,
            AppHeader,
            PlaqueImmat,
            Payer,
            ModalSearch,
            ModalImport,
            SlideReassurance,
            CustomDialog,
        },
        data() {
            return {
                isActive: false,
                texts: [
                    'Enregistrement de vos plaques',
                    'Génération en HD de vos fichiers',
                    'Génération des prévisualisations',
                    'Ajout des produits dans le panier',
                    'Envoi des données au serveur',
                    'Envoi des données au serveur',
                ],
                currentTextIndex: 0,
                duration: 2200,
                showText: false,
                $carousel: false,
                wheel: false,
                scrolling: false,
                showModalSearch: false,
                showModalImport: false,
                importImage: {},
                modalImportAction: '',
                modalSearchAction: '',
                modalSearchBackgroundColor: '',

                dialogStore: useDialogStore(),
            }
        },
        computed: {
            desktop() {
                return window.innerWidth > 1024
            },
            ...mapState(useStore, ['Config', 'PlaqueImmat', 'PlaqueModule']),
            text() {
                return this.texts[this.currentTextIndex]
            },
            dialogOptions() {
                return this.dialogStore.dialogOptions
            },
        },
        watch: {
            'PlaqueModule.onlyApproval': {
                handler(newValue, oldValue) {
                    consoleLog(
                        `PlaqueModule.onlyApproval from ${oldValue} to ${newValue}`
                    )
                    if (oldValue !== newValue) {
                        this.approvalSliders()
                    }
                },
            },
            'PlaqueModule.currentSlide': {
                handler(newValue, oldValue) {
                    consoleLog(
                        `PlaqueModule.currentSlide from ${oldValue} to ${newValue}`
                    )
                    if (oldValue !== newValue) {
                        //this.chgSlider(oldValue, newValue)
                    }
                },
            },
        },
        async mounted() {
            this.PlaqueModule.loading = false
            setInterval(() => {
                this.showText = false
                if (this.PlaqueModule.loading) {
                    setTimeout(() => {
                        this.currentTextIndex =
                            (this.currentTextIndex + 1) % this.texts.length
                        //this.showText = true;
                    }, this.duration / 2)
                }
            }, this.duration)
            window.addEventListener('pageshow', (event) => {
                if (event.persisted) {
                    window.location.reload()
                }
            })

            //this.approvalSliders();
        },
        methods: {
            approvalSliders() {
                /*
            this.$refs.navSlider.unfilter()
            this.$refs.mainSlider.unfilter()

            this.$refs.navSlider.reSlick()
            this.$refs.mainSlider.reSlick()

            this.$refs.navSlider.goTo(0, true)
            this.$refs.mainSlider.goTo(0, true)

            if (this.PlaqueModule.onlyApproval) {
                this.$refs.navSlider.filter(function () {
                    return $(this).find('.onlyApproval').length !== 0
                })

                this.$refs.mainSlider.filter(function () {
                    return $(this).find('.onlyApproval').length !== 0
                })
            }

            this.$refs.navSlider.reSlick()
            this.$refs.mainSlider.reSlick()*/
            },
            addToCart() {
                if (this.desktop) {
                    this.PlaqueModule.addToCart = true
                } else {
                    this.PlaqueModule.showPayer = !this.PlaqueModule.showPayer
                }
            },
            goBack() {
                const dialogStore = useDialogStore()
                dialogStore
                    .confirm({
                        title: 'Confirmation',
                        message:
                            'Voulez-vous retourner au site et perdre vos modifications ?',
                        okText: 'Oui',
                        cancelText: 'Non',
                    })
                    .then(() => {
                        if (this.PlaqueModule.redirect) {
                            window.location.href = this.PlaqueModule.redirect
                        } else {
                            window.location.href = this.Config.websiteUrl
                        }
                    })
                    .catch((error) => {
                        consoleLog(error)
                    })
            },
        },
    }
</script>

<style>
    html {
        touch-action: manipulation;
        /*user-select: none;*/
    }

    .text {
        font-size: 20px;
    }
</style>
