import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'
import Wrapper from './Wrapper.vue'

import './assets/style/style.scss'
import './assets/style/slider_style.scss'

// Définir vos routes
const routes = [
    {
        path: '/',
        component: Wrapper, // Utilise Wrapper pour décider dynamiquement du composant à afficher
    },
    {
        path: '/:id_product',
        props: true,
        component: Wrapper, // Utilise Wrapper pour décider dynamiquement du composant à afficher
    },
]

// Créer une instance de routeur
const router = createRouter({
    history: createWebHistory(),
    routes,
})

const pinia = createPinia()

const app = createApp(Wrapper)

app.use(pinia)
app.use(router)

//app.config.productionTip = false

app.mount('#app')
